import request from '@/utils/request';

export async function query_docker_list(params?: {
  [key: string]: any;
}): Promise<DockerConfigureResponse> {
  return request.post('/configure_manage/query_docker_list', params);
}

export async function delete_docker(params: Record<string, any>) {
  return request.post('/configure_manage/delete_docker', params);
}

export async function recover_docker(params: Record<string, any>) {
  return request.post('/configure_manage/recover_docker', params);
}

export async function create_docker(params: Record<string, any>) {
  return request.post('/configure_manage/create_docker', params);
}

export async function update_docker(params: Record<string, any>) {
  return request.post('/configure_manage/update_docker', params);
}

interface DockerConfigureItem {
  key: string | number;
  cert_path: string;
  create_time: string;
  id: number;
  is_active: boolean;
  key_path: string;
  name: string;
  update_time: string;
  url: string;
}

interface DockerConfigureResponse {
  current: number;
  data: DockerConfigureItem[];
  pageSize: string;
  success: boolean;
  total: number;
}
