<template>
  <page-container>
    <div class="ant-pro-table">
      <div class="ant-pro-table-search">
        <search-render
          ref="config_render"
          :form="config"
          :model="config.model"
          :options="config_options"
          :validateInfos="validateInfos"
          @search="search"
        />
      </div>
      <a-card :body-style="{ padding: 0 }" ref="elRef">
        <div class="ant-pro-table-list-toolbar">
          <div class="ant-pro-table-list-toolbar-container">
            <div class="ant-pro-table-list-toolbar-left">
              <div class="ant-pro-table-list-toolbar-title">docker配置</div>
            </div>
            <div class="ant-pro-table-list-toolbar-right">
              <a-space align="center">
                <a-button
                  type="primary"
                  @click="
                    () => {
                      editModal.visible = true;
                      editModal.title = '创建docker配置';
                      editModal.form.model = {};
                    }
                  "
                >
                  <plus-outlined />
                  创建docker配置
                </a-button>
                <div class="ant-pro-table-list-toolbar-setting-item">
                  <a-tooltip title="表格斑马纹">
                    <a-switch
                      checked-children="开"
                      un-checked-children="关"
                      v-model:checked="state.stripe"
                    />
                  </a-tooltip>
                </div>
              </a-space>
              <div class="ant-pro-table-list-toolbar-divider">
                <a-divider type="vertical" />
              </div>
              <div class="ant-pro-table-list-toolbar-setting-item">
                <a-tooltip title="刷新">
                  <reload-outlined @click="handleTableChange" />
                </a-tooltip>
              </div>
              <div class="ant-pro-table-list-toolbar-setting-item">
                <a-tooltip title="密度">
                  <a-dropdown :trigger="['click']" placement="bottomRight">
                    <column-height-outlined />
                    <template #overlay>
                      <a-menu
                        style="width: 80px"
                        :selected-keys="[state.tableSize]"
                        @click="
                          ({ key }) => {
                            state.tableSize = key;
                          }
                        "
                      >
                        <a-menu-item key="default">
                          <a href="javascript:;">默认</a>
                        </a-menu-item>
                        <a-menu-item key="middle">
                          <a href="javascript:;">中等</a>
                        </a-menu-item>
                        <a-menu-item key="small">
                          <a href="javascript:;">紧凑</a>
                        </a-menu-item>
                      </a-menu>
                    </template>
                  </a-dropdown>
                </a-tooltip>
              </div>
              <div class="ant-pro-table-list-toolbar-setting-item">
                <a-popover
                  placement="bottomRight"
                  arrowPointAtCenter
                  trigger="click"
                  overlayClassName="ant-pro-table-column-setting-overlay"
                >
                  <template #title>
                    <div class="ant-pro-table-column-setting-title">
                      <a-checkbox
                        v-model:checked="columnState.checkAll"
                        :indeterminate="columnState.indeterminate"
                        @change="handleColumnAllClick"
                      >
                        列展示
                      </a-checkbox>
                      <a @click="reset">重置</a>
                    </div>
                  </template>
                  <template #content>
                    <span class="ant-pro-table-column-setting-list">
                      <drag-container
                        lockAxis="y"
                        dragClass="ant-pro-table-drag-ghost"
                        dropClass="ant-pro-table-drop-ghost"
                        @drop="({ removedIndex, addedIndex }) => move(removedIndex, addedIndex)"
                      >
                        <draggable :key="column.key" v-for="column in dynamicColumnItems">
                          <div class="ant-pro-table-column-setting-list-item">
                            <drag-icon />
                            <a-checkbox
                              :checked="column.checked"
                              @change="handleColumnChange($event, column)"
                            >
                              {{ column.label }}
                            </a-checkbox>
                          </div>
                        </draggable>
                      </drag-container>
                    </span>
                  </template>
                  <a-tooltip title="列设置">
                    <setting-outlined />
                  </a-tooltip>
                </a-popover>
              </div>
              <div class="ant-pro-table-list-toolbar-setting-item">
                <a-tooltip :title="screenState ? '退出全屏' : '全屏'">
                  <fullscreen-outlined v-if="!screenState" @click="setFull" />
                  <fullscreen-exit-outlined v-else @click="exitFull" />
                </a-tooltip>
              </div>
            </div>
          </div>
        </div>
        <a-affix :offset-top="top">
          <a-table
            bordered
            :scroll="{ y: tableHeight.y }"
            :row-class-name="stripe"
            :size="state.tableSize"
            :loading="state.loading"
            :columns="dynamicColumns"
            :data-source="state.dataSource"
            :pagination="{
              current: state.current,
              pageSize: state.pageSize,
              total: state.total,
            }"
            @change="handleTableChange"
          >
            <template #index="{ index }">
              <span>{{ index + 1 + state.pageSize * (state.current - 1) }}</span>
            </template>
            <template #status="{ text }">
              <a-tag :color="statusMap[text].status">
                {{ statusMap[text].text }}
              </a-tag>
            </template>
            <template #action="{ record }">
              <a-button
                type="primary"
                @click="() => handleOpenEdit(record)"
                v-if="record.is_active"
              >
                修改
              </a-button>
              <a-popconfirm v-if="record.is_active" @confirm="() => handleDelete(record)">
                <template #title>
                  <p>
                    是否确认删除
                    <span style="color: dodgerblue">{{ record.name }}</span>
                    ?
                  </p>
                </template>
                <a-button type="danger">删除</a-button>
              </a-popconfirm>
              <a-button type="danger" @click="() => handleRecover(record)" v-if="!record.is_active">
                恢复
              </a-button>
            </template>
          </a-table>
        </a-affix>
      </a-card>
    </div>
    <form-modal
      v-bind="editModal"
      v-if="editModal.visible"
      @cancel="
        () => {
          editModal.visible = false;
        }
      "
      @ok="handlerOk"
    />
  </page-container>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive } from 'vue';
import { Badge, message } from 'ant-design-vue';
import {
  ColumnHeightOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
  PlusOutlined,
  ReloadOutlined,
  SettingOutlined,
} from '@ant-design/icons-vue';
import { Container as DragContainer, Draggable } from '@/components/draggable';
import {
  create_docker,
  delete_docker,
  query_docker_list,
  recover_docker,
  update_docker,
} from '@/api/docker_manager/docker-configure';
import { Pagination, TableColumn, TableColumnSortOrder, TableFilters } from '@/typing';
import { useFetchData } from '@/utils/hooks/useFetchData';
import { useFullscreen } from '@/utils/hooks/useFullscreen';
import { useTableDynamicColumns } from '@/utils/hooks/useTableColumn';
import DragIcon from '@/components/table/drag-icon.vue';
import FormModal from '../../../components/form-modal/form-modal.vue';
import { useStore } from 'vuex';
import { queryOrgList } from '@/api/sys';
import { useForm } from 'ant-design-vue/es/form';
import SearchRender from '@/components/FormRender/SearchRender.vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const statusMap = {
  true: { text: '正常', status: 'green' },
  false: { text: t('禁用'), status: 'red' },
};

const baseColumns: TableColumn[] = [
  {
    title: t('序号'),
    dataIndex: 'index',
    width: 50,
    slots: { customRender: 'index' },
  },
  {
    title: '名称',
    width: 90,
    dataIndex: 'name',
  },
  {
    title: '地址',
    width: 110,
    dataIndex: 'url',
  },
  {
    title: 'ca证书cert路径',
    width: 150,
    dataIndex: 'cert_path',
  },
  {
    title: 'ca证书key路径',
    width: 150,
    dataIndex: 'key_path',
  },
  {
    title: '状态',
    width: 70,
    dataIndex: 'is_active',
    slots: { customRender: 'status' },
    filters: [
      {
        text: status[0],
        value: true,
      },
      {
        text: status[1],
        value: false,
      },
    ],
  },
  {
    title: t('操作'),
    width: 200,
    dataIndex: 'action',
    slots: { customRender: 'action' },
  },
];

export default defineComponent({
  name: 'UserManage',
  setup() {
    const store = useStore();
    const current_org = store.getters['user/current_org'];
    const tableHeight = reactive({ y: window.innerHeight - 130 });
    onMounted(() => {
      window.onresize = function () {
        tableHeight.y = window.innerHeight - 130;
      };
    });
    const {
      state: columnState,
      dynamicColumns,
      dynamicColumnItems,
      handleColumnAllClick,
      handleColumnChange,
      reset,
      move,
    } = useTableDynamicColumns(baseColumns, true);
    const [elRef, screenState, { setFull, exitFull }] = useFullscreen();
    const org_list: any = reactive([]);
    queryOrgList().then(res => {
      res.data.forEach(org => {
        org_list.push(org);
      });
      console.log(org_list);
    });
    const modelRef = reactive({
      org_id: current_org.id,
      is_active: true,
      name: '',
    });
    const config_options = {
      is_active: [
        { name: '全部', value: '' },
        { name: '正常', value: true },
        { name: '禁用', value: false },
      ],
    };
    const config = {
      settings: {
        name: '表单名称',
        labelAlign: 'right',
        layout: 'inline',
      },
      fields: [
        {
          type: 'input',
          name: 'name',
          label: '名称',
          label_i18n: '名称',
          placeholder_i18n: '请填写名称',
          disabled: false,
          allowClear: true,
          inputType: 'text',
          maxlength: 100,
          defaultValue: '',
          placeholder: '请填写名称',
        },
        {
          type: 'select',
          name: 'is_active',
          label: '状态',
          label_i18n: '状态',
          mode: 'default',
          disabled: false,
          defaultValue: '',
          labelKey: 'name',
          valueKey: 'value',
          placeholder: '请选择状态',
          datasource: 'is_active',
        },
      ],
      rules: {},
      model: modelRef,
    };
    const { validateInfos } = useForm(modelRef, {});

    const { stripe, reload, setPageInfo, context: state } = useFetchData(query_docker_list, {
      current: 1,
      pageSize: 20,
      tableSize: 'middle', // 'default' | 'middle' | 'small'
      stripe: true,
      requestParams: {
        ...modelRef,
      },
    });
    const handleTableChange = (
      { current, pageSize }: Pagination,
      filters: TableFilters,
      sorter: TableColumnSortOrder,
    ) => {
      console.log(sorter);
      setPageInfo({
        current: current ? current : state.current,
        pageSize: pageSize ? pageSize : state.pageSize,
        ...filters,
        ...modelRef,
      });

      reload();
    };

    const search = () => {
      setPageInfo({
        current: 1,
        ...modelRef,
      });
      reload();
    };
    // edit
    const edit_model_config = {
      settings: {
        name: '表单名称',
        labelAlign: 'right',
        layout: 'vertical',
      },
      fields: [
        {
          type: 'input',
          name: 'name',
          label: '名称',
          label_i18n: '名称',
          placeholder_i18n: '请填写名称',
          disabled: false,
          allowClear: true,
          inputType: 'text',
          maxlength: 100,
          defaultValue: '',
          placeholder: '请填名称',
        },
        {
          type: 'input',
          name: 'url',
          label: '地址',
          disabled: false,
          allowClear: true,
          inputType: 'text',
          maxlength: 100,
          defaultValue: '',
          placeholder: '请填地址',
        },
        {
          type: 'input',
          name: 'cert_path',
          label: 'ca证书路径',
          disabled: false,
          allowClear: true,
          inputType: 'text',
          maxlength: 100,
          defaultValue: '',
          placeholder: '请填ca证书路径',
        },
        {
          type: 'input',
          name: 'key_path',
          label: 'ca证书key路径',
          disabled: false,
          allowClear: true,
          inputType: 'text',
          maxlength: 100,
          defaultValue: '',
          placeholder: '请填ca证书key路径',
        },
      ],
      rules: {
        url: [{ required: true, message: '请填地址' }],
      },
      model: {
        id: null,
        name: '',
        url: '',
        cert_path: '',
        key_path: '',
      },
    };
    const edit_model_config_options = {};
    const editModal = reactive({
      visible: false,
      title: '',
      form: edit_model_config,
      options: edit_model_config_options,
    });
    let currentRecord = reactive({ name: '' });
    const handleOpenEdit = (record: any) => {
      editModal.visible = true;
      editModal.title = '修改';
      editModal.form.model = {
        ...record,
        username: record.user__username,
        docker_id: record.id,
      };
      currentRecord = record;
    };

    const handleDelete = (record: any) => {
      delete_docker({ org_id: current_org.id, docker_id: record.id }).then(res => {
        message.success(t('删除成功'));
        Object.assign(record, res);
      });
    };
    const handleRecover = (record: any) => {
      recover_docker({ org_id: current_org.id, docker_id: record.id }).then(res => {
        message.success(t('恢复成功'));
        Object.assign(record, res);
      });
    };

    const handlerOk = (data: any) => {
      if (!editModal.form.model.id) {
        create_docker({ org_id: current_org.id, ...data }).then(() => {
          message.success('新增成功');
          reload();
        });
      } else {
        update_docker({
          ...data,
          org_id: current_org.id,
          role_id: editModal.form.model.id,
        }).then((res: any) => {
          Object.assign(currentRecord, res);
          message.success(t('修改成功'));
        });
      }
      editModal.visible = false;
      // reload();
    };

    return {
      statusMap,
      tableHeight,
      state,
      org_list,
      stripe,
      columnState,
      dynamicColumns,
      dynamicColumnItems,

      handlerOk,
      currentRecord,
      modelRef,
      validateInfos,
      // fullscreen
      elRef,
      screenState,
      setFull,
      exitFull,

      handleTableChange,
      search,

      handleColumnChange,
      handleColumnAllClick,
      reset,
      move,
      current_org,
      message,
      create_docker,
      update_docker,
      delete_docker,
      recover_docker,
      // edit
      edit_model_config,
      edit_model_config_options,
      editModal,
      handleOpenEdit,
      handleDelete,
      handleRecover,
      reload,
      config,
      config_options,
    };
  },
  components: {
    DragIcon,
    PlusOutlined,
    ReloadOutlined,
    ColumnHeightOutlined,
    SettingOutlined,
    FullscreenOutlined,
    FullscreenExitOutlined,
    Draggable,
    DragContainer,
    [Badge.name]: Badge,
    FormModal,
    SearchRender,
  },
});
</script>
